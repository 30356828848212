import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import { usePostMutation } from "../../../redux/apis";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner";
import { useOutletContext } from "react-router-dom";
import { ispGetDisconnectService } from "../../../utility/ispManagerPrams";

const Disconnection: React.FC = () => {
  const { accountid, isp, prams, serviceid, shawId, username }: any =
    useOutletContext();
  const [disconnectionDate, setDisconnectionDate] = useState("");
  const [disconnect, { isLoading }] = usePostMutation();
  const { customer } = useSelector((state: any) => state.customer);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { address, modem, person, serviceType, technologyType } = customer;
    const transactionData = {
      disconnectDate: moment(disconnectionDate).format("MM/DD/YYYY HH:mm:ss"),
      createdBy: "joshua@ciphertv.com",
      address,
      modem,
      person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
      serviceType,
      technologyType,
    };
    try {
      const { data } = await disconnect({
        actionName: "servicerequests_disconnect",
        transactionData,
        ispmgr: ispGetDisconnectService(transactionData),
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      e.target.reset();
    } catch (e) {}
  };

  return (
    <div className="p-4">
      <FieldSectionCard title="Disconnection" columns={1}>
        <form onSubmit={handleSubmit} className="col-span-3">
          <FormField
            label="Disconnection Date *"
            type="date"
            value={disconnectionDate}
            onChange={(e) => setDisconnectionDate(e.target.value)}
            placeholder="Disconnection Date"
          />
          <FormField
            label=""
            type="submit"
            buttonLabel="Submit"
            disable={isLoading}
            loading={isLoading}
          />
        </form>
      </FieldSectionCard>
    </div>
  );
};

export default Disconnection;
