import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePostMutation } from "../../redux/apis";
import FieldSectionCard from "../FieldSectionCard";
import FormField from "../FormField";
import LoadingButton from "../LoadingButton";
import { toast } from "react-toastify";
import AccountInformation from "../CustomerDetails/CustomerSupport/AccountInfomation";
const ExistingCustomerForm: React.FC<any> = ({
  setFormOpen,
  setViewComponent,
}) => {
  const customer = useSelector((state: any) => state.customer.customer);
  const { person, modem, address, serviceType }: any = customer;

  const [formData, setFormData] = useState({
    additionalInfo1: "",
    additionalInfo2: "",
    provinceSourceType: "west",
    requestType: "Service Order Request",
  });

  const prepareTransactionData = (data: any) => {
    return {
      createdBy: "joshua@ciphertv.com",
      trxMode: "Manual",
      internetPlan: "",
      technologyType: "Cable",
      person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
      modem,
      address,
      serviceType,
      additionalInfo1: data.additionalInfo1,
      additionalInfo2: data.additionalInfo2,
      provinceSourceType: "west",
      miscellaneousType: "Existing",
      fileName: "",
      requestType: data.requestType,
    };
  };

  const setRequestType = (type: any) => {
    setFormData((prevData) => ({
      ...prevData,
      requestType: type,
    }));
  };
  const [postNewOrderService, { isLoading }] = usePostMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formEntries = new FormData(event.target as HTMLFormElement).entries();
    const form: any = Object.fromEntries(formEntries);

    const mergeData = {
      ...formData,
      ...form,
    };

    try {
      const preparedData = prepareTransactionData(mergeData);
      let response = await postNewOrderService({
        actionName: "miscellaneous",
        transactionData: preparedData,
      });
      if (response.data?.trxId) {
        toast(`${response?.data?.response}, ${response?.data?.trxId}`);
        setFormOpen(false);
      } else {
        toast(response?.errorMsg);
      }
    } catch (err: any) {}
  };

  return (
    <div className="container mx-auto">
      <AccountInformation />
      <form className="space-y-6 mt-2" onSubmit={handleSubmit}>
        <FieldSectionCard title="Additional Information" columns={1}>
          <label className="block text-sm font-medium text-gray-700">
            Request Type
          </label>
          <div className="flex gap-2">
            <input
              type="radio"
              name="requestType"
              onChange={(e) => setRequestType(e.target.value)}
              value="Service Order Request"
            />
            <label htmlFor="requestType">Service Order Request</label>
            <input
              type="radio"
              name="requestType"
              onChange={(e) => setRequestType(e.target.value)}
              value="Technical Request"
            />
            <label htmlFor="requestType">Technical Request</label>
          </div>
          <FormField
            label="Additional Info 1"
            type="textarea"
            name="additionalInfo1"
            placeholder="Additional Info 1"
          />
          <FormField
            label="Additional Info 2"
            type="textarea"
            name="additionalInfo2"
            placeholder="Additional Info 2"
          />

          <div className="flex flex-wrap sm:content-center gap-5 space-x-4 ml-4 mb-4">
            <button
              type="button"
              onClick={() => setViewComponent(false)}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="bg-red-500 text-white py-2 px-4 rounded disabled:bg-gray-300 flex gap-2"
            >
              Submit {isLoading && <LoadingButton />}
            </button>
          </div>
        </FieldSectionCard>
      </form>
    </div>
  );
};

export default ExistingCustomerForm;
