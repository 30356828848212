import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
} from "../../../redux/apis";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import LoadingSpinner from "../../LoadingSpinner";
import DateWindowSelector from "../../DateWindowSelector";
import moment from "moment";
import { toast } from "react-toastify";
import { postalRegex, residentialPhone } from "../../../Constants/regex";
import { ispGetMoveAddress } from "../../../utility/ispManagerPrams";

const MoveCustomer: React.FC<any> = ({ setFormOpen }) => {
  const [show, setShow] = useState(false);
  const [addressCounter, setAddressCounter] = useState(0);
  const { customer } = useSelector((state: any) => state.customer);
  const [getPostalCodeService, { data: getPostal, isFetching: postalLoading }] =
    useLazyGetQuery();
  const [
    getAddressService,
    { data: getAddress, isError, isFetching: addressLoading },
  ] = useLazyGetQuery();

  const { data } = useGetQuery(
    {
      actionName: "getProvinceAllWest",
      transactionData: { Parm: "west" },
    },
    { skip: isError }
  );
  const provinceWestOptions: any = Object.keys(data || {}).map((key) => ({
    label: key,
    value: key,
  }));
  const [move, { isLoading }] = usePostMutation();

  const handlePostalCodeSubmit = async (e: any) => {
    e.preventDefault();
    setShow(false);
    const { disconnectDate, ...formData } = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );

    try {
      const { data } = await getPostalCodeService({
        actionName: "getServiceabilityInfo",
        transactionData: { pc: formData.pc },
      });
      if (data?.response || data?.responseMsg) {
        const result = await getAddressService({
          actionName: "getUniqueServiceAddressList",
          transactionData: formData,
        });
        if (
          result?.data?.response ||
          result?.data?.responseMsg ||
          result?.data?.responseDttm
        ) {
          setShow(true);
          setAddressCounter(0);
        } else {
          if (addressCounter >= 2) {
            setShow(true);
          }
          setAddressCounter((n) => n + 1);
        }
      }
    } catch (e) {}
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const {
      disconnectDate,
      option1,
      option2,
      preferredWindow1,
      preferredWindow2,
      buzzCode,
      municipality,
      pc,
      province,
      nearestIntersection,
      residentialPhone,
      aptNum,
      stType,
      stName,
      stNumber,
    }: any = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );

    const { person, serviceType, modem, technologyType } = customer;
    const transactionData = {
      createdBy: "joshua@ciphertv.com",
      trxMode: "Manual",
      address: {
        streetNumber: stNumber,
        streetName: stName.replace("'", "''"),
        streetType: stType,
        suite: aptNum,
        seqNum: getAddress?.accountAddresses?.[1]?.seqNum,
        buzzCode,
        city: municipality,
        postalCode: pc,
        province,
        phone: residentialPhone,
        nearestInter: nearestIntersection,
      },
      person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
      disconnectDate: moment(disconnectDate).format("MM/DD/YYYY"),
      appointment: {
        option1: moment(option1).format("MM/DD/YYYY"),
        option2: moment(option2).format("MM/DD/YYYY"),
        option3: "",
        preferredWindow1,
        preferredWindow2,
        preferredWindow3: "",
      },
      sourceLongitude: getAddress?.accountAddresses?.[1]?.longitude,
      sourceLatitude: getAddress?.accountAddresses?.[1]?.latitude,
      mapAreaCode: getAddress?.accountAddresses?.[1]?.mapAreaCode,
      phubCode: getAddress?.accountAddresses?.[0]?.ntwkEntryPoint?.phub,
      contractGroupCode: getAddress?.accountAddresses?.[1]?.contractGroupCode,
      direction: "",
      companyNum: getAddress?.accountAddresses?.[1]?.companyNum,
      technologyType,
      serviceType,
      modem,
    };
    try {
      const { data } = await move({
        actionName: "servicerequests_moveCustomer",
        transactionData,
        ispmgr: ispGetMoveAddress(transactionData),
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      (document.getElementById("postalForm") as HTMLFormElement).reset();
      setShow(false);
      setAddressCounter(0);
      e.target.reset();
    } catch (e) {}
  };

  const handleReset = () => {
    (document.getElementById("postalForm") as HTMLFormElement).reset();
    setShow(false);
    setAddressCounter(0);
  };

  if (isLoading) {
    <LoadingSpinner />;
  }
  return (
    <>
      <form onSubmit={handlePostalCodeSubmit} id="postalForm">
        <FieldSectionCard title="Move Customer" columns={2}>
          <FormField
            required
            label="Postal Code"
            type="text"
            name="pc"
            pattern={postalRegex.regex}
            title={postalRegex.title}
            placeholder="Postal Code"
          />
          {getPostal?.errMsg && (
            <p className="text-red-500">{getPostal?.errMsg}</p>
          )}
        </FieldSectionCard>
        <FieldSectionCard title="Address Verification" columns={2}>
          <FormField
            label="Suite Number *"
            type="text"
            name="aptNum"
            placeholder="Suite Number *"
          />
          <FormField
            required
            label=" Street Number *"
            type="text"
            name="stNumber"
            placeholder=" Street Number *"
          />
          <FormField
            required
            label="Street Name *"
            type="text"
            name="stName"
            placeholder="Street Name *"
          />
          <FormField
            required
            label="Street Type *"
            type="text"
            name="stType"
            placeholder="Street Type *"
          />

          <div className="flex gap-2">
            <FormField
              label=""
              type="button"
              onClick={handleReset}
              buttonLabel="Reset"
            />
            <FormField
              label=""
              type="submit"
              buttonLabel="Submit"
              disable={postalLoading || addressLoading}
              loading={postalLoading || addressLoading}
            />
          </div>
          {isError && (
            <p className="font-bold text-red-500 p-3">
              {addressCounter >= 3
                ? "Serviceability check failed. This request requires manual support."
                : "This address is not serviceable."}
            </p>
          )}
        </FieldSectionCard>
      </form>
      <form onSubmit={handleSubmit}>
        {show && (
          <>
            <FieldSectionCard title="Address" columns={3}>
              <FormField
                required
                label="Date of Disconnection at old Address *"
                type="date"
                name="disconnectDate"
                placeholder="Disconnection Date"
              />
              <FormField
                label="Street Number"
                type="text"
                name="stNumber"
                value={getAddress?.accountAddresses?.[1]?.streetNum}
                readonly={addressCounter <= 3}
                placeholder="Street Number"
                required
              />
              <FormField
                label="Street Name"
                type="text"
                name="stName"
                value={getAddress?.accountAddresses?.[1]?.streetName}
                readonly={addressCounter <= 3}
                placeholder="Street Name"
                required
              />
              <FormField
                label="Street Type"
                type="text"
                name="stType"
                value={getAddress?.accountAddresses?.[1]?.streetType}
                readonly={addressCounter <= 3}
                placeholder="Street Type"
                required
              />
              <FormField
                label="Suite/Unit#"
                type="text"
                name="aptNum"
                value={getAddress?.accountAddresses?.[1]?.aptNum}
                placeholder="Suite/Unit#"
              />
              <FormField
                label="Buzz Code"
                type="text"
                name="buzzCode"
                placeholder="Buzz Code"
              />
              <FormField
                label="City/Municipality"
                type="text"
                name="municipality"
                defualtValue={getAddress?.accountAddresses?.[1]?.municipality}
                placeholder="City/Municipality"
                required
              />
              <FormField
                label="Postal Code"
                type="text"
                name="pc"
                value={getAddress?.accountAddresses?.[1]?.postalCode}
                readonly={addressCounter <= 3}
                placeholder="Postal Code"
                required
              />
              <FormField
                label="Province"
                type="select"
                name="province"
                value={getAddress?.accountAddresses?.[1]?.province}
                options={provinceWestOptions}
                required
              />
              <FormField
                label="Residential Phone# (No VOIP)"
                type="text"
                name="residentialPhone"
                // value={getAddress?.accountAddresses?.[1]?.residentialPhone}
                placeholder="Residential Phone# (No VOIP)"
                pattern={residentialPhone.regex}
                title={residentialPhone.title}
                required
              />
              <FormField
                label="Nearest Main Intersection"
                type="text"
                name="nearestIntersection"
                // value={getAddress?.accountAddresses?.[1]?.nearestIntersection}
                placeholder="Nearest Main Intersection"
              />
            </FieldSectionCard>
            <FieldSectionCard title="Appointment" columns={1}>
              <DateWindowSelector />
            </FieldSectionCard>
            <FormField
              label=""
              type="submit"
              buttonLabel="Submit"
              loading={isLoading}
              disable={isLoading}
            />
          </>
        )}
      </form>
    </>
  );
};

export default MoveCustomer;
