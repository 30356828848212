import moment from "moment";

const params = window.location.search;
const queryParams = new URLSearchParams(params);
// const auth = queryParams.get("auth");
const serviceid = queryParams.get("serviceid");
const username = queryParams.get("username");
const accountid = queryParams.get("accountid");
const isp = queryParams.get("isp");
const shawId = queryParams.get("shawId");

export const ispGetChangeofService = (data: any) => {
  const { changeOfSpeed, modem, serviceType } = data;
  const ispmgr = {
    accountID: accountid,
    isp: isp,
    user: username,
    oldservtype: serviceType.serviceType,
    olduserservid: serviceid,
    newservid: null,
    servdate: new Date().toLocaleDateString("en-CA"),
    shaworderid: shawId,
    emailcontent: null,
    newname: null,
    newphone: null,
    newservicename: changeOfSpeed,
    newserial: modem.modemEsn,
    newmac: modem.modemMac,
    newmodel: modem.modemModel,
  };
  return ispmgr;
};

export const ispGetDisconnectService = (data: any) => {
  const ispmgr = {
    accountID: accountid,
    isp,
    user: username,
    servid: serviceid,
    cdate: moment(new Date()).format("MMDDYYYY"),
    email: "email",
    reason: "reason",
    servicename: "servicerequests_disconnect",
    canceldate: moment(new Date()).format("YYYY/MM/DD"),
  };
  return ispmgr;
};

export const ispGetReconnectService = (data: any) => {
  const { newModem } = data;
  const ispmgr = {
    accountID: accountid,
    isp,
    user: username,
    mac: newModem.modemMac,
  };
  return ispmgr;
};

export const ispGetModemReplacement = (data: any) => {
  const { newModem, oldModem } = data;
  const ispmgr = {
    accountID: accountid,
    isp,
    user: username,
    newservid: serviceid,
    oldmac: oldModem?.modemMac,
    oldserial: oldModem?.modemEsn,
    newmac: newModem.modemMac,
    newserial: newModem.modemEsn,
    modemname: newModem.modemModel,
    emailcontent: "emailcontent",
  };
  return ispmgr;
};

export const ispGetMoveOutlate = (data: any) => {
  const {
    address,
    modem,
    person,
    serviceType,
    technologyType,
    relocateDetails,
  } = data;
  const {
    phone,
    suite,
    streetNumber,
    streetName,
    streetType,
    city,
    postalCode,
    province,
  } = address;
  const { option1, preferredWindow1 } = relocateDetails.timeSlot;
  const ispmgr = {
    accountID: accountid,
    isp,
    user: username,
    servid: serviceid,
    unit: suite,
    house: streetNumber,
    address: `${streetNumber + " " + streetName + " " + streetType}`,
    city,
    province,
    code: postalCode,
    phone,
    servtype: serviceType.serviceType,
    servdate: moment(new Date()).format("MMDDYYYY"),
    servtime: moment(new Date()).format("MMDDYYYY"),
    modemname: modem.modemModel,
    modemserial: modem.modemEsn,
    modemmac: modem.modemMac,
    notes: "",
    emailcontent: "emailcontent",
    item_id: null,
    status: null,
    basic_charge: null,
    billing_cycle: null,
    include: null,
    exceeded_charge: null,
    nbd: moment(new Date()).format("MMDDYYYY"),
    payment_method: null,
    sitephone: null,
    cdate: moment(new Date()).format("MMDDYYYY"),
    contactname: `${person.firstName + " " + person.lastName}`,
    shawstatus: null,
    dispatch: null,
    buzz: null,
    altphone: null,
    region: null,
    ordertypename: null,
    sales: null,
    saledate: moment(new Date()).format("MMDDYYYY"),
    ordertype: null,
    ordertypeshawid: shawId,
  };
  return ispmgr;
};

export const ispGetMoveAddress = (data: any) => {
  const { address, modem, person, serviceType, appointment } = data;
  const {
    phone,
    suite,
    streetNumber,
    streetName,
    streetType,
    city,
    postalCode,
    province,
  } = address;
  const { option1, preferredWindow1 } = appointment;
  const ispmgr = {
    accountID: accountid,
    isp,
    user: username,
    servid: serviceid,
    unit: suite,
    house: streetNumber,
    address: `${streetNumber + " " + streetName + " " + streetType}`,
    city,
    province,
    code: postalCode,
    phone,
    servtype: serviceType.serviceType,
    servdate: moment(new Date()).format("MMDDYYYY"),
    servtime: moment(new Date()).format("MMDDYYYY"),
    modemname: modem.modemModel,
    modemserial: modem.modemEsn,
    modemmac: modem.modemMac,
    notes: "",
    emailcontent: "emailcontent",
    item_id: null,
    status: null,
    basic_charge: null,
    billing_cycle: null,
    include: null,
    exceeded_charge: null,
    nbd: moment(new Date()).format("MMDDYYYY"),
    payment_method: null,
    sitephone: null,
    cdate: moment(new Date()).format("MMDDYYYY"),
    contactname: `${person.firstName + " " + person.lastName}`,
    shawstatus: null,
    dispatch: null,
    buzz: null,
    altphone: null,
    region: null,
    ordertypename: null,
    sales: null,
    saledate: moment(new Date()).format("MMDDYYYY"),
    ordertype: null,
    ordertypeshawid: shawId,
  };
  return ispmgr;
};
