import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import ModemDiagnosticInformation from "./ModemDiagnosticInformation";
import moment from "moment";
import { toast } from "react-toastify";
import {
  useGetQuery,
  useImageMutation,
  usePostMutation,
} from "../../../redux/apis";
import { useSelector } from "react-redux";
import Radio from "../../Radio";
import TextArea from "../../TextArea";
import LoadingSpinner from "../../LoadingSpinner";
import { getQuestions } from "../../../utility/questions";

const SlowSpeedTroubleshooting: React.FC<any> = ({ modemDetails }) => {
  const [skipGetQuestions, setSkipGetQuestions] = useState(false);

  const { customer } = useSelector((state: any) => state.customer);
  const { data, isLoading: getLoading } = useGetQuery(
    {
      actionName: "fetchtroubleshootingquestions",
      transactionData: {
        troubleshootingType: "Slow Speed Troubleshooting",
        technologyType: customer.technologyType,
      },
    },
    { skip: skipGetQuestions }
  );
  const [rf, { isLoading }] = usePostMutation();
  const [image] = useImageMutation();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { person, address, modem, serviceType, technologyType } = customer;
    const { fileName, modem_connected, ...form }: any = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );
    const troubleshooting = Object.entries(form).map(([key, value]) => ({
      questionId: key,
      response: value,
      tsType: "Slow Speed Troubleshooting",
    }));
    try {
      // if (fileName) {
      //   await image(fileName);
      // }
      const { data } = await rf({
        actionName: "submittroubleshooting",
        body: {
          createdBy: "joshua@ciphertv.com",
          person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
          address,
          modem,
          serviceType,
          technologyType,
          modem_connected: modem_connected,
          timeSlot: {
            option1: "",
            option2: "",
            option3: "",
            preferredWindow1: "",
            preferredWindow2: "",
            preferredWindow3: "",
          },
          troubleshooting,
          fileName: fileName?.name || "",
        },
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      setSkipGetQuestions(true);
      e.target.reset();
    } catch (error) {}
  };

  if (getLoading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="p-4">
      <FieldSectionCard title="Slow Speed Troubleshooting">
        <ModemDiagnosticInformation modemDetails={modemDetails} />
        <p className="text-red-500 mt-4">
          This form should only be used in the event that the customer's modem
          is online and the customer is experiencing speeds slower than
          expected.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Connected directly to the modem via an Ethernet cable</li>
          <li>NOT running any peer to peer applications</li>
          <li>NOT running any virus scanning software during testing</li>
          <li>NOT running a firewall during testing</li>
        </ul>
        <p className="text-red-500">Please answer all mandatory questions.</p>
        <form onSubmit={handleSubmit}>
          {getQuestions(data)}
          <FormField
            label="Is the modem connected via USB or RJ45? *"
            type="select"
            name="modem_connected"
            options={[
              { label: "Select...", value: "" },
              { label: "USB", value: "USB" },
              { label: "RJ45", value: "RJ45" },
            ]}
            required
          />
          <div className="my-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-900"
              htmlFor="file_input"
            >
              Upload file
            </label>
            <input
              className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              name="fileName"
            />
          </div>
          <FormField
            label=""
            type="submit"
            buttonLabel="Submit"
            loading={isLoading}
            disable={isLoading}
          />
        </form>
      </FieldSectionCard>
    </div>
  );
};

export default SlowSpeedTroubleshooting;
