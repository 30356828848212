import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import DateWindowSelector from "../../DateWindowSelector";
import {
  useCustomGetQuery,
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
} from "../../../redux/apis";
import LoadingSpinner from "../../LoadingSpinner";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { macRegex } from "../../../Constants/regex";
import { ispGetReconnectService } from "../../../utility/ispManagerPrams";
import { useOutletContext } from "react-router-dom";

const Reconnection: React.FC = () => {
  const { isp }: any = useOutletContext();
  const { customer } = useSelector((state: any) => state.customer);
  const [modemModel, setModemModel] = useState("");
  const [modemMac, setModemMac] = useState("");
  const [modemEsn, setModemEsn] = useState("");
  const [serviceType, setServiceType] = useState("");

  const { data: modemData, isLoading } = useGetQuery({
    actionName: "getmodemModelWest",
    transactionData: {},
  });
  const modemDropdownValues = Object?.entries(modemData || {}).map(
    ([key, value]: any) => ({ value: value, label: key })
  );

  const { data: serviceOptionsData, isFetching } = useCustomGetQuery(
    {
      actionName: "servicetype",
      transactionData: {
        isp,
      },
    },
    { skip: !modemModel }
  );

  const serviceDropdownValues = serviceOptionsData?.transaction?.map(
    (value: any) => ({ value: value?.rogerValue, label: value?.rogerValue })
  );

  const [Reconnect, { isLoading: postLoading }] = usePostMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const {
      modemModel,
      modemEsn,
      modemMac,
      serviceType,
      option1,
      preferredWindow1,
      option2,
      preferredWindow2,
    }: any = Object.fromEntries(
      new FormData(event.target as HTMLFormElement).entries()
    );

    const transactionData = {
      address: customer.address,
      person: { ...customer.person, beCode: "WE12", tpiaName: "AEBC" },
      serviceType: { serviceType },
      oldModem: customer.modem,
      newModem: { modemModel, modemEsn, modemMac },
      timeSlot: {
        option1: moment(option1).format("MM/DD/YYYY"),
        option2: moment(option2).format("MM/DD/YYYY"),
        option3: "",
        preferredWindow1,
        preferredWindow2,
        preferredWindow3: "",
        roleName: "Internet Access Partner Admin",
      },
      install_type: "TECH-INSTALL",
      createdBy: "joshua@ciphertv.com",
      technologyType: customer.technologyType,
    };
    try {
      const { data } = await Reconnect({
        actionName: "servicerequests_reconnect",
        transactionData,
        ispmgr: ispGetReconnectService(transactionData),
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      event.target.reset();
    } catch (e) {}
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <form onSubmit={handleSubmit} className="p-4">
      <FieldSectionCard title="New Modem Details" columns={3}>
        <FormField
          label="Modem Model *"
          name="modemModel"
          type="select"
          value={modemModel}
          onChange={(e) => setModemModel(e.target.value)}
          options={modemDropdownValues}
          placeholder="Select Modem Model"
        />
        <FormField
          label="Modem ESN *"
          type="text"
          name="modemEsn"
          value={modemEsn}
          onChange={(e) => setModemEsn(e.target.value)}
          placeholder="Modem ESN"
        />
        <FormField
          label="Modem MAC *"
          type="text"
          value={modemMac}
          onChange={(e) => setModemMac(e.target.value)}
          name="modemMac"
          pattern={macRegex.regex}
          title={macRegex.title}
          placeholder="Modem MAC"
        />
      </FieldSectionCard>
      <FieldSectionCard title="Service" columns={3}>
        <FormField
          label={`Service Type * ${isFetching ? "Loading ..." : ""}`}
          type="select"
          name="serviceType"
          value={serviceType}
          onChange={(e) => setServiceType(e.target.value)}
          options={serviceDropdownValues}
          placeholder="Select Service Type"
          disable={isFetching}
        />
      </FieldSectionCard>
      <FieldSectionCard title="Reconnect" columns={1}>
        <DateWindowSelector />
      </FieldSectionCard>
      <FormField
        label=""
        type="submit"
        buttonLabel="Submit"
        loading={postLoading}
        disable={postLoading}
      />
    </form>
  );
};

export default Reconnection;
