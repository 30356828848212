import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAddress } from "../../redux/customerSlice";
import { validatePostalCode } from "../../utility/validation";
import LoadingButton from "../LoadingButton";
import { useLazyGetQuery } from "../../redux/apis";
import { ImCancelCircle } from "react-icons/im";
import { FaCheck, FaCheckDouble } from "react-icons/fa";
import { postalRegex } from "../../Constants/regex";
import { toast } from "react-toastify";
const CheckAvailability: React.FC<any> = ({
  setFormOpen,
  setInstallType,
  setWithoutAddressVerify,
  address,
}) => {
  const dispatch = useDispatch();
  const [verifyAttemptCount, setVerifyAttemptCount] = useState(0);
  const [
    getAddressService,
    { data: getAddress, isLoading: addressLoading, isError: addressIsError },
  ] = address;
  const [addressValidate, setAddressValidate] = useState(false);
  const [addressAvailability, setAddressAvailability] = useState({
    pc: "",
    aptNum: "",
    stName: "",
    stType: "",
    stNumber: "",
  });

  let [
    getPostalCodeService,
    { data: getPostal, isLoading: postalLoading, isError: postalError },
  ] = useLazyGetQuery();

  // let getPostal: any;
  useEffect(() => {
    // Update addressValidate based on addressAvailability
    const { pc, aptNum, stName, stType, stNumber } = addressAvailability;
    setAddressValidate(
      pc !== "" && stName !== "" && stType !== "" && stNumber !== ""
    );
  }, [addressAvailability]);

  const handlePostalCodeSubmit = async () => {
    if (validatePostalCode(addressAvailability.pc)) {
      try {
        await getPostalCodeService({
          actionName: "getServiceabilityInfo",
          transactionData: { pc: addressAvailability.pc },
        });
      } catch (e) {}
    }
  };

  const handleAddressSubmit = async () => {
    try {
      const res = await getAddressService({
        actionName: "getUniqueServiceAddressList",
        transactionData: {
          ...addressAvailability,
          stName: addressAvailability?.stName.replace("'", "''"),
        },
      });
      setVerifyAttemptCount(verifyAttemptCount + 1);
    } catch (e) {}
  };

  const handleAddressChange = (e: any) => {
    setAddressAvailability({
      ...addressAvailability,
      [e.target.name]: e.target.value,
    });
  };

  const handleContinue = () => {
    const { pc, aptNum, stName, stType, stNumber } = addressAvailability;
    if (pc && stName && stType && stNumber) {
      if (getAddress?.accountAddresses?.[1]) {
        dispatch(setAddress(getAddress?.accountAddresses?.[1]));
      }
      setFormOpen("Verified");
    }
  };

  const handleContinueManual = () => {
    const { pc, aptNum, stName, stType, stNumber } = addressAvailability;
    if (pc && stName && stType && stNumber) {
      if (getAddress?.accountAddresses?.[1]) {
        dispatch(setAddress(getAddress?.accountAddresses?.[1]));
      }
      setFormOpen("Manual");
    }
  };
  const handleReset = () => {
    getPostal = null;
    setAddressAvailability({
      pc: "",
      aptNum: "",
      stName: "",
      stType: "",
      stNumber: "",
    });
  };

  return (
    <div className="">
      <main className="mt-5">
        <div className="bg-gray-100 px-10 rounded-lg shadow flex flex-col sm:flex-row">
          <div className="bg-gray-100 p-4">
            <h2 className="text-2xl font-normal text-gray-900 mb-2">
              Address Availability Checking
            </h2>
            <hr />
            <div className="border-b-2 border-primary w-24 mb-6"></div>

            <div className="my-4 flex flex-col sm:flex-row gap-4">
              <div className="flex items-center mb-2">
                <input
                  type="radio"
                  id="new_install"
                  name="install_type"
                  value="new"
                  defaultChecked
                  onChange={(e) => setInstallType(e.target.value)}
                  className="mr-2"
                />
                <label htmlFor="new_install" className="text-gray-700">
                  New Install
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="radio"
                  id="transfer"
                  name="install_type"
                  value="transfer"
                  onChange={(e) => setInstallType(e.target.value)}
                  className="mr-2"
                />
                <label htmlFor="transfer" className="text-gray-700">
                  Transfer
                </label>
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="postalCode"
                className="block text-gray-700 font-normal mb-2"
              >
                Postal Code *
              </label>
              <input
                id="postalCode"
                name="pc"
                type="text"
                value={addressAvailability.pc}
                onChange={handleAddressChange}
                className={`lg:w-full sm:w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 ${
                  validatePostalCode(addressAvailability.pc)
                    ? "focus:ring-green-500"
                    : "focus:ring-red-500 border-red-500"
                }`}
                pattern={postalRegex.regex}
                title={postalRegex.title}
                required
                placeholder="Postal Code"
              />
              {postalError && (
                <p className="text-secondary font-semibold flex mt-2 gap-2">
                  {" "}
                  <ImCancelCircle className="m-1" /> Somthing is wrong contact
                  with support
                </p>
              )}
              {getPostal?.errMsg && (
                <p className="text-secondary font-semibold flex mt-2 gap-2">
                  {" "}
                  <ImCancelCircle className="m-1" /> {getPostal?.errMsg}
                </p>
              )}
              {getPostal?.response && (
                <p className="text-green-500 font-semibold flex mt-2 gap-2">
                  {" "}
                  <FaCheck className="m-1" /> {getPostal?.response}
                </p>
              )}
            </div>

            <div className="flex space-x-4">
              <button
                onClick={handleReset}
                className="w-auto py-2 px-4 bg-primary text-white rounded-lg hover:bg-secondary"
              >
                Reset
              </button>
              <button
                onClick={handlePostalCodeSubmit}
                disabled={!validatePostalCode(addressAvailability.pc)}
                className={`flex gap-2 w-auto py-2 px-4 bg-secondary text-white rounded-lg hover:bg-primary ${
                  !validatePostalCode(addressAvailability.pc)
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                Check Availability {postalLoading && <LoadingButton />}
              </button>
            </div>
            {getPostal?.response && (
              <div className="pt-5">
                <div className="flex flex-col sm:flex-row gap-4 mt-5">
                  <div className="">
                    <label
                      htmlFor="aptNum"
                      className="block text-gray-700 font-normal mb-2"
                    >
                      Suite Number *
                    </label>
                    <input
                      id="aptNum"
                      name="aptNum"
                      type="text"
                      value={addressAvailability.aptNum}
                      onChange={handleAddressChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-red-500"
                      placeholder="Suite Number"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="stNumber"
                      className="block text-gray-700 font-normal mb-2"
                    >
                      Street Number *
                    </label>
                    <input
                      id="stNumber"
                      name="stNumber"
                      type="text"
                      value={addressAvailability.stNumber}
                      onChange={handleAddressChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-red-500"
                      placeholder="Street Number"
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-4 mt-5">
                  <div className="">
                    <label
                      htmlFor="stName"
                      className="block text-gray-700 font-normal mb-2"
                    >
                      Street Name *
                    </label>
                    <input
                      id="stName"
                      name="stName"
                      type="text"
                      value={addressAvailability.stName}
                      onChange={handleAddressChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-red-500"
                      placeholder="Street Name"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="stType"
                      className="block text-gray-700 font-normal mb-2"
                    >
                      Street Type *
                    </label>
                    <input
                      id="stType"
                      name="stType"
                      type="text"
                      value={addressAvailability.stType}
                      onChange={handleAddressChange}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-red-500"
                      placeholder="Street Type"
                    />
                  </div>
                </div>
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={handleReset}
                    className="w-auto py-2 px-4 bg-primary text-white rounded-lg hover:bg-secondary"
                  >
                    Reset
                  </button>
                  <button
                    onClick={handleAddressSubmit}
                    disabled={!addressValidate || addressLoading}
                    className={`flex gap-2 w-auto py-2 px-4 bg-secondary text-white rounded-lg hover:bg-primary ${
                      !addressValidate ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    Check Availability {addressLoading && <LoadingButton />}
                  </button>
                </div>
                <div className="m-5">
                  {addressIsError || getAddress?.errMsg ? (
                    <>
                      {getAddress?.errMsg ? (
                        <p className="text-secondary font-semibold flex mt-2 gap-2">
                          <ImCancelCircle className="m-1" /> Address is not
                          Serviceable
                        </p>
                      ) : (
                        <p className="text-secondary font-semibold flex mt-2 gap-2">
                          {verifyAttemptCount >= 3 ? (
                            <div className="flex items-center gap-3">
                              <ImCancelCircle className="m-1" />
                              Still Address is not verified, But you can try
                              manual
                              <button
                                onClick={handleContinueManual}
                                className="w-auto py-2 px-4 bg-secondary text-white rounded-lg hover:bg-primary"
                              >
                                Continue
                              </button>
                            </div>
                          ) : (
                            <>
                              <ImCancelCircle className="m-1" />
                              Address is not Serviceable or somthing is wrong
                            </>
                          )}
                        </p>
                      )}
                    </>
                  ) : null}
                  {getAddress?.accountAddresses && (
                    <p className="text-green-500 font-semibold flex mt-2 gap-2">
                      <FaCheckDouble className="mt-1" />
                      {`${addressAvailability.stNumber} ${addressAvailability.stName} ${addressAvailability.stType} is serviceable.`}
                    </p>
                  )}
                </div>
                {getAddress?.accountAddresses && (
                  <div className="flex space-x-4 mt-5">
                    <button
                      onClick={handleReset}
                      className="w-auto py-2 px-4 bg-primary text-white rounded-lg hover:bg-secondary"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleContinue}
                      className="w-auto py-2 px-4 bg-secondary text-white rounded-lg hover:bg-primary"
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckAvailability;
