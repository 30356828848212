import React, { useState, useEffect } from "react";
import { useGetQuery } from "../../redux/apis";
import LoadingSpinner from "../LoadingSpinner";
import FieldSectionCard from "../FieldSectionCard";
import FormField from "../FormField";

export const OpenTransactionView: React.FC<any> = ({ requestType, id }) => {
  const [data, setData] = useState<any>();
  const {
    data: dataView,
    isLoading: ViewLoading,
    isError: ViewError,
  } = useGetQuery(
    {
      actionName: "transaction_openRequest",
      transactionData: { transactionId: id },
    },
    { skip: !id }
  );

  useEffect(() => {
    if (dataView?.transactionData?.[0]) {
      setData(dataView.transactionData[0]);
    }
  }, [dataView]);

  if (ViewLoading) return <LoadingSpinner />;
  if (ViewError)
    return (
      <div className="m-5 rounded-md text-white bg-secondary text-center p-5 font-semibold">
        Unable to fetch record
      </div>
    );

  // return (
  //   <>
  //     <div className="px-4">
  //       <FieldSectionCard title="Change of Service" columns={3}>
  //         <FormField
  //           required
  //           label="Modem Model"
  //           type="text"
  //           value={data?.modemModel || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Speed Tier"
  //           type="text"
  //           value={data?.speedTier || ""}
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //     <div className="px-4">
  //       <FieldSectionCard title="Reschedule Request" columns={3}>
  //         <FormField
  //           required
  //           label="Previous Transaction Id"
  //           type="text"
  //           value={data?.prevTrxId || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Previous Transaction Type"
  //           type="text"
  //           value={data?.prevTrxType || ""}
  //           disable
  //         />

  //         <FormField
  //           required
  //           label="Prefered Date1"
  //           type="text"
  //           value={data?.prefDate1 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Window1"
  //           type="text"
  //           value={data?.prefWindow1 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date2"
  //           type="text"
  //           value={data?.prefDate2 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Window2"
  //           type="text"
  //           value={data?.prefWindow2 || ""}
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //     <div className="px-4">
  //       <FieldSectionCard title="Reconnect Customer" columns={3}>
  //         <FormField
  //           required
  //           label="Modem Model"
  //           type="text"
  //           value={data?.modemModel || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Modem MAC"
  //           type="text"
  //           value={data?.modemMac || ""}
  //           disable
  //         />

  //         <FormField
  //           required
  //           label="Modem ESN"
  //           type="text"
  //           value={data?.modemEsn || ""}
  //           disable
  //         />

  //         <FormField
  //           required
  //           label="Service Type"
  //           type="text"
  //           value={data?.serviceType || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date1"
  //           type="text"
  //           value={data?.prefDate1 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Window1"
  //           type="text"
  //           value={data?.prefWindow1 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date2"
  //           type="text"
  //           value={data?.prefDate2 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Window2"
  //           type="text"
  //           value={data?.prefWindow2 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date3"
  //           type="text"
  //           value={data?.prefDate3 || ""}
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //     <div className="px-4">
  //       <FieldSectionCard title="Move Customer Address" columns={3}>
  //         <FormField
  //           required
  //           label="Postal Code"
  //           type="text"
  //           name="pc"
  //           value={data?.postalCode || ""}
  //           placeholder="Postal Code"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Province"
  //           type="text"
  //           name="province"
  //           value={data?.province || ""}
  //           placeholder="Province"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Street Name"
  //           type="text"
  //           name="streetName"
  //           value={data?.streetName || ""}
  //           placeholder="Street Name"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Street Number"
  //           type="text"
  //           name="StreetNumber"
  //           value={data?.streetNum || ""}
  //           placeholder="Street Number"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Street Type"
  //           type="text"
  //           name="StreetType"
  //           value={data?.streetType || ""}
  //           placeholder="StreetType"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Suite"
  //           type="text"
  //           name="suite"
  //           value={data?.suite || ""}
  //           placeholder="suite"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Disconnect Date"
  //           type="text"
  //           name="suite"
  //           value={data?.disconnectDate || ""}
  //           placeholder="suite"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date"
  //           type="text"
  //           name="suite"
  //           value={data?.prefDate1 || ""}
  //           placeholder="suite"
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Time"
  //           type="text"
  //           name="suite"
  //           value={data?.prefWindow1 || ""}
  //           placeholder="suite"
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //     <div className="px-4">
  //       <FieldSectionCard title="Disconnect Customer" columns={3}>
  //         <FormField
  //           required
  //           label="Disconnect Date"
  //           type="text"
  //           name="suite"
  //           value={data?.disconnectDate || ""}
  //           placeholder="suite"
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //     <div className="px-4">
  //       <FieldSectionCard title="Relocate Outlet" columns={3}>
  //         <FormField
  //           required
  //           label="Phone"
  //           type="text"
  //           value={data?.residentialPhone || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date1"
  //           type="text"
  //           value={data?.prefDate1 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Window1"
  //           type="text"
  //           value={data?.prefWindow1 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date2"
  //           type="text"
  //           value={data?.prefDate2 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Window2"
  //           type="text"
  //           value={data?.prefWindow2 || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Prefered Date3"
  //           type="text"
  //           value={data?.prefDate3 || ""}
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //     <div className="px-4">
  //       <FieldSectionCard title="Swap Modem" columns={3}>
  //         <FormField
  //           required
  //           label="Modem Model"
  //           type="text"
  //           value={data?.modemModel || ""}
  //           disable
  //         />
  //         <FormField
  //           required
  //           label="Modem MAC"
  //           type="text"
  //           value={data?.modemMac || ""}
  //           disable
  //         />

  //         <FormField
  //           required
  //           label="Modem ESN"
  //           type="text"
  //           value={data?.modemEsn || ""}
  //           disable
  //         />

  //         <FormField
  //           required
  //           label="Prefered Date"
  //           type="text"
  //           name="suite"
  //           value={data?.prefDate1 || ""}
  //           placeholder="suite"
  //           disable
  //         />
  //       </FieldSectionCard>
  //     </div>
  //   </>
  // );
  switch (requestType) {
    case "Reschedule":
      return (
        <div className="px-4">
          <FieldSectionCard title="Reschedule Request" columns={3}>
            <FormField
              required
              label="Previous Transaction Id"
              type="text"
              value={data.prevTrxId || ""}
              disable
            />
            <FormField
              required
              label="Previous Transaction Type"
              type="text"
              value={data.prevTrxType || ""}
              disable
            />

            <FormField
              required
              label="Prefered Date1"
              type="text"
              value={data.prefDate1 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Window1"
              type="text"
              value={data.prefWindow1 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date2"
              type="text"
              value={data.prefDate2 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Window2"
              type="text"
              value={data.prefWindow2 || ""}
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "Change of Service":
      return (
        <div className="px-4">
          <FieldSectionCard title="Change of Service" columns={3}>
            <FormField
              required
              label="Modem Model"
              type="text"
              value={data?.modemModel || ""}
              disable
            />
            <FormField
              required
              label="Speed Tier"
              type="text"
              value={data?.speedTier || ""}
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "Reconnect":
      return (
        <div className="px-4">
          <FieldSectionCard title="Reconnect Customer" columns={3}>
            <FormField
              required
              label="Modem Model"
              type="text"
              value={data?.modemModel || ""}
              disable
            />
            <FormField
              required
              label="Modem MAC"
              type="text"
              value={data?.modemMac || ""}
              disable
            />

            <FormField
              required
              label="Modem ESN"
              type="text"
              value={data?.modemEsn || ""}
              disable
            />

            <FormField
              required
              label="Service Type"
              type="text"
              value={data?.serviceType || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date1"
              type="text"
              value={data?.prefDate1 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Window1"
              type="text"
              value={data?.prefWindow1 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date2"
              type="text"
              value={data?.prefDate2 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Window2"
              type="text"
              value={data?.prefWindow2 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date3"
              type="text"
              value={data?.prefDate3 || ""}
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "Move":
      return (
        <div className="px-4">
          <FieldSectionCard title="Move Customer Address" columns={3}>
            <FormField
              required
              label="Postal Code"
              type="text"
              name="pc"
              value={data?.postalCode || ""}
              placeholder="Postal Code"
              disable
            />
            <FormField
              required
              label="Province"
              type="text"
              name="province"
              value={data?.province || ""}
              placeholder="Province"
              disable
            />
            <FormField
              required
              label="Street Name"
              type="text"
              name="streetName"
              value={data?.streetName || ""}
              placeholder="Street Name"
              disable
            />
            <FormField
              required
              label="Street Number"
              type="text"
              name="StreetNumber"
              value={data?.streetNum || ""}
              placeholder="Street Number"
              disable
            />
            <FormField
              required
              label="Street Type"
              type="text"
              name="StreetType"
              value={data?.streetType || ""}
              placeholder="StreetType"
              disable
            />
            <FormField
              required
              label="Suite"
              type="text"
              name="suite"
              value={data?.suite || ""}
              placeholder="suite"
              disable
            />
            <FormField
              required
              label="Disconnect Date"
              type="text"
              name="suite"
              value={data?.disconnectDate || ""}
              placeholder="suite"
              disable
            />
            <FormField
              required
              label="Prefered Date"
              type="text"
              name="suite"
              value={data?.prefDate1 || ""}
              placeholder="suite"
              disable
            />
            <FormField
              required
              label="Prefered Time"
              type="text"
              name="suite"
              value={data?.prefWindow1 || ""}
              placeholder="suite"
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "Disconnect":
      return (
        <div className="px-4">
          <FieldSectionCard title="Disconnect Customer" columns={3}>
            <FormField
              required
              label="Disconnect Date"
              type="text"
              name="suite"
              value={data?.disconnectDate || ""}
              placeholder="suite"
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "Relocate Outlet":
      return (
        <div className="px-4">
          <FieldSectionCard title="Relocate Outlet" columns={3}>
            <FormField
              required
              label="Phone"
              type="text"
              value={data?.residentialPhone || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date1"
              type="text"
              value={data?.prefDate1 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Window1"
              type="text"
              value={data?.prefWindow1 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date2"
              type="text"
              value={data?.prefDate2 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Window2"
              type="text"
              value={data?.prefWindow2 || ""}
              disable
            />
            <FormField
              required
              label="Prefered Date3"
              type="text"
              value={data?.prefDate3 || ""}
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "Modem Replacement":
      return (
        <div className="px-4">
          <FieldSectionCard title="Swap Modem" columns={3}>
            <FormField
              required
              label="Modem Model"
              type="text"
              value={data?.modemModel || ""}
              disable
            />
            <FormField
              required
              label="Modem MAC"
              type="text"
              value={data?.modemMac || ""}
              disable
            />

            <FormField
              required
              label="Modem ESN"
              type="text"
              value={data?.modemEsn || ""}
              disable
            />

            <FormField
              required
              label="Prefered Date"
              type="text"
              name="suite"
              value={data?.prefDate1 || ""}
              placeholder="suite"
              disable
            />
          </FieldSectionCard>
        </div>
      );

    case "RF Troubleshooting":
    case "DHCP Troubleshooting":
    case "Service Routing Issue Troubleshooting":
    case "Provisioning Issue Troubleshooting":
    case "ONT Troubleshooting":
    case "Slow Speed Troubleshooting":
    case "Miscellaneous":
    case "Capacity Request":
    case "Cancel":
      return (
        <div className="text-center">
          Unable to fetch complete Data? of Request type: {requestType}
        </div>
      );
    default:
      return (
        <div className="text-center">Unknown request type {requestType}</div>
      );
  }
};
