import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetQuery,
  usePostCustomMutation,
  usePostMutation,
  useSyncTrxIdMutation,
} from "../redux/apis";
import SidebarNavigation from "../components/SidebarNavigation";
import LoadingSpinner from "../components/LoadingSpinner";
import { setCustomer } from "../redux/customerSlice";
import { RootState } from "../redux/store";
import FormField from "../components/FormField";
import ViewTransaction from "../components/HomeScreen/ViewTransaction";

const CustomerDetails: React.FC = () => {
  const customer = useSelector((state: RootState) => state.customer.customer);

  const [queryParams] = useSearchParams();
  const dispatch = useDispatch();

  const auth = queryParams.get("auth");
  const serviceid = queryParams.get("serviceid");
  const username = queryParams.get("username");

  const accountid = queryParams.get("accountid");
  const isp = queryParams.get("isp");
  const shaw = queryParams.get("shawId");

  let shawId = JSON.stringify(shaw);
  shawId = shawId.replace(/[^0-9.]/g, "");
  const prams = `?auth=${auth}&username=${username}&serviceid=${serviceid}&accountid=${accountid}&shawId=${shawId}&isp=${isp}`;
  const { data: existingClientData, isLoading: existingClientLoading } =
    useGetQuery(
      {
        actionName: "servicerequests_account",
        transactionData: { rogersAccountNumber: shawId },
      },
      { skip: !shawId || customer?.person }
    );

  const [searchTrxId, { data: trxData, isLoading: trxLoading }] =
    usePostMutation();
  const [syncTrxId, syncTrxData] = useSyncTrxIdMutation();
  const currentTransaction = trxData?.transaction?.[0];

  const updateTrxId = async () => {
    await syncTrxId({
      id: currentTransaction?.rogersAccountNo,
    });
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const { id } = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );
    await searchTrxId({
      actionName: "transaction_search",
      transactionData: {
        trxId: id,
        workorderId: "",
        txnType: "",
        createdDate: "",
        slaDate: "",
        modifyBy: "",
        status: "",
        technologyType: "",
        rogersAccountNo: "",
        faId: "",
        searchType: "searchBtn_empty",
        pageNumber: 1,
        sourceType: "",
      },
    });
  };
  if (!serviceid || !accountid || !isp) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-semibold">Missing query parameters</h1>
      </div>
    );
  }

  if (existingClientLoading) {
    return (
      <div className="lg:px-10 lg:pt-5 w-full h-screen">
        <LoadingSpinner />
      </div>
    );
  }
  // if (!shaw) {
  //   return (
  //     <form
  //       onSubmit={onSubmit}
  //       className="flex flex-col justify-center items-center p-8"
  //     >
  //       <FormField
  //         required
  //         label="Transaction Id"
  //         type="number"
  //         name="id"
  //         placeholder="Transaction Id"
  //       />
  //       <button
  //         type="submit"
  //         disabled={trxLoading}
  //         className="bg-blue-500 text-white py-2 px-4 rounded disabled:bg-gray-300 flex gap-2"
  //       >
  //         Submit
  //       </button>

  //       {currentTransaction && (
  //         <div className="p-8">
  //           <h2 className="text-2xl font-normal text-gray-900 mb-2">
  //             Transaction Details
  //           </h2>
  //           <hr />
  //           <div className="border-b-2 border-primary w-24 mb-6"></div>

  //           <div className="bg-white rounded-lg shadow p-6">
  //             <h3 className="text-lg font-semibold text-gray-900 mb-4">
  //               Summary
  //             </h3>
  //             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Transaction ID
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.trxId}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Type
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.trxType || "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Creation Date
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.createdDate || "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Created By
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.createdBy || "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Rogers Account Number
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.rogersAccountNo || "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Work Order ID
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.workorderId ||
  //                     currentTransaction?.workOrderId ||
  //                     "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Service Request/Ticket ID
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.ticketId || "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Service Call Work Order ID
  //                 </label>
  //                 <p className="py-2 px-4 border rounded">
  //                   {currentTransaction?.serviceCallId || "-"}
  //                 </p>
  //               </div>
  //               <div>
  //                 <label className="block text-gray-700 font-normal mb-2">
  //                   Status
  //                 </label>
  //                 <div className="w-96 flex gap-5">
  //                   <p className="w-56 px-5 py-2 border rounded">
  //                     {currentTransaction?.status || "-"}
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //       {currentTransaction?.rogersAccountNo && (
  //         <button
  //           type="button"
  //           className="bg-blue-500 text-white py-2 px-4 rounded disabled:bg-gray-300 flex gap-2"
  //           onClick={updateTrxId}
  //           disabled={syncTrxData?.isLoading}
  //         >
  //           Sync Rogger's Id
  //         </button>
  //       )}
  //       <span>{syncTrxData?.data}</span>
  //     </form>
  //   );
  // }
  if (existingClientData?.person?.rogersAccountNum || customer?.person) {
    dispatch(setCustomer(existingClientData || customer));
  } else {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-semibold">
          Something went wrong while fetching customer information
        </h1>
      </div>
    );
  }

  return (
    <div className="lg:px-10 lg:pt-5">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/4 lg:w-1/4 p-2">
          <SidebarNavigation prams={prams} shawId={shawId} />
        </div>
        <div className="md:w-3/4">
          <Outlet
            context={{ username, accountid, serviceid, shawId, isp, prams }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
