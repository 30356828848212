import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useCustomGetQuery,
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
} from "../../redux/apis";
import { FaExclamationCircle } from "react-icons/fa";
import FieldSectionCard from "../FieldSectionCard";
import FormField from "../FormField";
import LoadingButton from "../LoadingButton";
import DateWindowSelector from "../DateWindowSelector";
import { isValidMacAddress } from "../../utility/validation";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { HiRefresh } from "react-icons/hi";
import { residentialPhone } from "../../Constants/regex";
import moment from "moment";

interface isptype {
  username: string;
  accountid: string;
  isp: string;
  serviceid: string;
}

const CreateNewCustomerForm: React.FC<any> = ({
  setFormOpen,
  installType,
  ispData,
  api: [action, address],
}) => {
  const { accountid, username, isp }: isptype = ispData;
  console.log("address?.data", address?.data);
  const [formData, setFormData] = useState({
    remoteActivation: "yes",
    customerType: "Residential",
    firstName: "",
    lastName: "",
    streetNumber: address?.data?.accountAddresses?.[1]?.streetNum,
    streetName: address?.data?.accountAddresses?.[1]?.streetName,
    streetType: address?.data?.accountAddresses?.[1]?.streetType,
    suiteUnit: address?.data?.accountAddresses?.[1]?.aptNum,
    buzzCode: "",
    municipality: address?.data?.accountAddresses?.[1]?.municipality,
    postalCode: address?.data?.accountAddresses?.[1]?.postalCode,
    province: address?.data?.accountAddresses?.[1]?.province,
    residentialPhone: "",
    createdBy: "joshua@ciphertv.com",
    sourceLongitude: address?.data?.accountAddresses?.[1]?.longitude,
    sourceLatitude: address?.data?.accountAddresses?.[1]?.latitude,
    mapAreaCode: address?.data?.accountAddresses?.[1]?.mapAreaCode,
    nearestIntersection: "",
    modemModel: "",
    modemDesc: "",
    modemESN: "",
    modemMAC: "",
    serviceType: "",
    option1: null,
    option2: null,
    seqNum: address?.accountAddresses?.[1]?.seqNum,
    prefDate1: "",
    prefDate2: "",
    disconnectDate: "",
    transferDate: "",
    transferKey: "",
    trxMode: "Manual",
  });

  const [macAddressError, setMacAddressError] = useState<string | null>(null);

  const [remoteActivation, SetRemoteActivation] = useState("yes");
  const [selectModem, setSelectedModem] = useState({
    modemName: null,
    modemDesc: null,
  });

  const [otherInfo, setOtherInfo] = useState({
    contactName: "",
    alternatePhone: "",
  });

  const validateForm = (formData: any) => {
    const requiredFields = [
      "customerType",
      "firstName",
      "lastName",
      "streetNumber",
      "streetName",
      "streetType",
      "municipality",
      "postalCode",
      "province",
      "modemESN",
      "modemMAC",
      "serviceType",
    ];

    return requiredFields.every((field) => formData[field] !== "");
  };

  const isFormValid = validateForm(formData) && !macAddressError;
  const prepareTransactionData = (data: any) => {
    return {
      transactionData: [
        {
          beCode: "WE12",
          tpiaAccountNumber: "",
          customerType: data.customerType || "Residential",
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          streetNum: data.streetNumber || "",
          streetName: data.streetName || "",
          streetType: data.streetType || "",
          suite: data.suiteUnit || "",
          buzzCode: data.buzzCode || "",
          city: data.municipality || "",
          postalCode: data.postalCode || "",
          province: data.province || "",
          residentialPhone: data.residentialPhone || "",
          nearestMainIntersection: data.nearestIntersection || "",
          serviceType: data.serviceType || "",
          modemModel: selectModem.modemName || "",
          modemMac: data.modemMAC || "",
          modemEsn: data.modemESN || "",
          trxMode: installType === "new" ? "Auto" : "Manual", // Static field
          phubCode: "VC-CD", // Static field
          contractGroupCode: "4", // Static field
          direction: "", // Static field
          companyNum: "200", // Static field
          aptNum: data.suiteUnit || "",
          seqNum: data.seqNum || "",
          createdBy: data.createdBy || "joshua@ciphertv.com",
          modemDesc: selectModem.modemDesc || "",
          sourceLongitude: data.sourceLongitude || "",
          sourceLatitude: data.sourceLatitude || "",
          mapAreaCode: data.mapAreaCode || "",
          prefDate1: data.option1 ? format(data?.option1, "MM/dd/yyyy") : "",
          prefDate2: data.option2 ? format(data?.option2, "MM/dd/yyyy") : "",
          prefWindow1: data.preferredWindow1 || "",
          prefWindow2: data.preferredWindow2 || "",
          disconnectDate: data.disconnectDate
            ? format(data?.disconnectDate, "MM/dd/yyyy")
            : "",
          transferDate: data.transferDate
            ? format(data?.transferDate, "MM/dd/yyyy")
            : "",
          transferKey: data.transferKey || "",
        },
      ],
      technologyType: "Cable",
      customerInstallPreference:
        installType === "transfer"
          ? "TECH-INSTALL"
          : remoteActivation === "yes"
          ? "SELF-INSTALL"
          : "TECH-INSTALL",
      rfogKey: `WS12${data.postalCode}${data.streetName}${data.streetType}${data.streetNumber}${data.suiteUnit}`, // Derived field
    };
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "modemMAC") {
      if (!isValidMacAddress(value)) {
        setMacAddressError("Invalid MAC format");
      } else {
        setMacAddressError(null);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModemChange = (e: any) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedModem((prevData) => ({
      ...prevData,
      modemName: selectedOption.text,
      modemDesc: selectedOption.value,
    }));
  };

  const [postNewOrderService, { isLoading: submitIsLoading }] =
    usePostMutation();

  const [
    checkModemStatus,
    { data: modemStatusData, isLoading: modemStatusLoading },
  ] = usePostMutation();

  const { data: provinceData } = useGetQuery({
    actionName: "getProvinceAllWest",
    transactionData: {},
  });

  const provincemedOptions = Object?.entries(provinceData || {}).map(
    ([key, value]: any) => ({ value: value, label: key })
  );

  const { data: modemData, isLoading } = useGetQuery({
    actionName: "getmodemModelWest",
    transactionData: {},
  });

  let modemDropdownValues;

  if (modemData) {
    modemDropdownValues = Object?.entries(modemData || {}).map(
      ([key, value]: any) => ({ value: value, label: key })
    );
  }
  const [fetchModem, { data: modemDataLazzy, isLoading: modemLazzyLoading }] =
    useLazyGetQuery();

  const handleFetchModem = async () => {
    await fetchModem({
      actionName: "getmodemModelWest",
      transactionData: {},
    });
    modemDropdownValues = Object?.entries(modemDataLazzy || {}).map(
      ([key, value]: any) => ({ value: value, label: key })
    );
  };

  const { data: serviceOptionsData, isLoading: serviceOptionsLoading } =
    useCustomGetQuery(
      {
        actionName: "servicetype",
        transactionData: {
          isp,
        },
      },
      { skip: !selectModem.modemDesc }
    );

  const serviceDropdownValues = serviceOptionsData?.transaction?.map(
    (value: any) => ({ value: value?.rogerValue, label: value?.rogerValue })
  );
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formEntries = new FormData(event.target as HTMLFormElement).entries();
    const form: any = Object.fromEntries(formEntries);

    const mergeData = {
      ...formData,
      ...form,
    };

    const { data } = await checkModemStatus({
      actionName: "newCustomer_newInstall_getcheckModemStatus",
      transactionData: {
        serialNum: form.modemESN,
        macAddress: form.modemMAC,
        modemModelDescription: selectModem.modemName,
        postalCode: formData.postalCode,
      },
      ispmgr: {},
    });

    if (data?.errorDescription !== null) {
      return;
    }

    if (isFormValid) {
      try {
        const preparedData = prepareTransactionData(mergeData);
        const {
          serviceType,
          modemMac,
          modemEsn,
          firstName,
          lastName,
          streetNum,
          streetName,
          streetType,
          suite,
          buzzCode,
          city,
          aptNum,
          postalCode,
          province,
          mapAreaCode,
          residentialPhone,
        } = preparedData.transactionData[0];
        const ispmgr = {
          accountID: accountid,
          isp: isp,
          user: username,
          name: `${firstName} ${lastName}`,
          address: `${suite} ${streetNum} ${streetName} ${streetType}, ${city}, ${postalCode} ${province}`,
          buzz: buzzCode,
          house: suite,
          cdate: moment(new Date()).format("MMDDYYYY"),
          city: city,
          code: mapAreaCode,
          oldservtype: serviceType,
          phone: residentialPhone,
          province: province,
          region: "West",
          saledate: moment(new Date()).format("MMDDYYYY"),
          sales: "NA",
          servdate: moment(new Date()).format("MMDDYYYY"),
          servtime: moment(new Date()).format("MMDDYYYY"),
          servtype: serviceType,
          olduserservid: null,
          newservid: null,
          shaworderid: null,
          emailcontent: "NA",
          newname: null,
          newphone: residentialPhone,
          newservicename: serviceType,
          item_id: 0,
          nbd: moment(new Date()).format("MMDDYYYY"),
          notes:
            installType === "transfer"
              ? "Transfer Installation From New GUI"
              : "SELF-INSTALL From New GUI",
          ordertype: "Cable service",
          otype: "Cable service",
          modemmac: modemMac,
          modemname: selectModem.modemName,
          modemserial: modemEsn,
          newserial: modemEsn,
          newmac: modemMac,
          newmodel: selectModem.modemName,
          shawstatus: 3,
          sitephone: residentialPhone,
          status: "Pending",
          unit: aptNum,
          contactname: otherInfo.contactName, //textbox
          dispatch: 0,
          include: 0,
          altphone: otherInfo.alternatePhone, //textbox
          basic_charge: 0,
          billing_cycle: 0,
          whole: null,
        };

        let response;
        if (installType === "transfer") {
          response = await postNewOrderService({
            actionName: "transaction_submitTransfer_add",
            transactionData: preparedData,
            ispmgr,
          });
        } else {
          response = await postNewOrderService({
            actionName: "postneworder",
            transactionData: preparedData,
            ispmgr,
          });
        }
        if (response.data?.trxId) {
          toast.info(`${response?.data?.response}, ${response?.data?.trxId}`);
          setFormOpen(null);
          event.target.reset();
        } else {
          toast.info(response?.errorMsg);
        }
      } catch (err: any) {}
    }
  };

  const title =
    installType === "transfer" ? "Transfer Customer" : "New Install";
  return (
    <div className="container mx-auto">
      <form className="space-y-6 mt-2" onSubmit={handleSubmit}>
        <FieldSectionCard title={title} columns={1}>
          {installType === "new" && (
            <>
              <p>
                The address is eligible for remote activation. Would you like to
                proceed with remote activation?
              </p>
              <div className="flex items-center mt-2">
                <label className="mr-4">
                  <input
                    type="radio"
                    name="remoteActivation"
                    onChange={(e) => SetRemoteActivation(e.target.value)}
                    value="yes"
                    defaultChecked
                    className="mr-2"
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="remoteActivation"
                    onChange={(e) => SetRemoteActivation(e.target.value)}
                    value="no"
                    className="mr-2"
                  />{" "}
                  No
                </label>
              </div>
            </>
          )}
          <FieldSectionCard title="Customer Details" columns={3}>
            <FormField
              label="Customer Type"
              type="select"
              name="customerType"
              value={formData.customerType}
              onChange={handleChange}
              options={[
                { label: "Residential", value: "Residential" },
                { label: "Commercial", value: "Commercial" },
              ]}
            />
            <FormField
              label="First Name"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
            />
            <FormField
              label="Last Name"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
          </FieldSectionCard>

          <FieldSectionCard title="Address" columns={3}>
            <FormField
              label="Street Number"
              type="text"
              name="streetNumber"
              value={formData.streetNumber}
              disable={true}
              onChange={handleChange}
              placeholder="Street Number"
            />
            <FormField
              label="Street Name"
              type="text"
              name="streetName"
              value={formData.streetName}
              disable={true}
              onChange={handleChange}
              placeholder="Street Name"
            />
            <FormField
              label="Street Type"
              type="text"
              name="streetType"
              value={formData.streetType}
              disable={true}
              onChange={handleChange}
              placeholder="Street Type"
            />
            <FormField
              label="Suite/Unit#"
              type="text"
              name="suiteUnit"
              value={formData.suiteUnit}
              onChange={handleChange}
              placeholder="Suite/Unit#"
            />
            <FormField
              label="Buzz Code"
              type="text"
              name="buzzCode"
              value={formData.buzzCode}
              onChange={handleChange}
              placeholder="Buzz Code"
            />
            <FormField
              label="City/Municipality"
              type="text"
              name="municipality"
              value={formData.municipality}
              onChange={handleChange}
              placeholder="City/Municipality"
              required
            />
            <FormField
              label="Postal Code"
              type="text"
              name="postalCode"
              value={formData.postalCode}
              disable={true}
              onChange={handleChange}
              placeholder="Postal Code"
            />
            <FormField
              label="Province"
              type="select"
              name="province"
              value={formData.province}
              onChange={handleChange}
              options={provincemedOptions}
              required
            />
            <FormField
              label="Residential Phone# (No VOIP)"
              type="text"
              name="residentialPhone"
              value={formData.residentialPhone}
              onChange={handleChange}
              placeholder="Residential Phone# (No VOIP)"
              pattern={residentialPhone.regex}
              title={residentialPhone.title}
              required
            />
            <FormField
              label="Nearest Main Intersection"
              type="text"
              name="nearestIntersection"
              value={formData.nearestIntersection}
              onChange={handleChange}
              placeholder="Nearest Main Intersection"
            />

            <FormField
              label="Alternate Phone"
              type="text"
              value={otherInfo.alternatePhone}
              onChange={(e) =>
                setOtherInfo({
                  ...otherInfo,
                  alternatePhone: e.target.value,
                })
              }
              placeholder="Alternate Phone"
            />
            <FormField
              label="Contact Person Name"
              type="text"
              value={otherInfo.contactName}
              onChange={(e) =>
                setOtherInfo({
                  ...otherInfo,
                  contactName: e.target.value,
                })
              }
              placeholder="Enter Name"
            />
          </FieldSectionCard>

          <FieldSectionCard title="Modem Details" columns={3}>
            <div className="flex items-center">
              <FormField
                label="Modem Model"
                type="select"
                name="modemModel"
                onChange={handleModemChange}
                options={modemDropdownValues}
                required
              />
              <HiRefresh
                size={30}
                onClick={() => handleFetchModem()}
                className="cursor-pointer"
              />

              {isLoading && <LoadingButton />}
              {modemLazzyLoading && <LoadingButton />}
            </div>
            <FormField
              label="Modem ESN"
              type="text"
              name="modemESN"
              value={formData.modemESN}
              onChange={handleChange}
              placeholder="Modem ESN"
              required
            />
            <div className="relative">
              <FormField
                label="Modem MAC"
                type="text"
                name="modemMAC"
                value={formData.modemMAC}
                onChange={handleChange}
                placeholder="Example: 001A2B3C4D5E"
                required
              />
              {macAddressError && (
                <div className=" inset-y-0 right-0 pr-3 flex items-center text-red-500">
                  <FaExclamationCircle />
                  <span className="ml-2">{macAddressError}</span>
                </div>
              )}
            </div>
          </FieldSectionCard>
          <span className="text-lg text-red-400">
            {modemStatusData?.errorDescription}
          </span>
          <FieldSectionCard title="Service" columns={3}>
            <FormField
              label="Service Type"
              type="select"
              name="serviceType"
              value={formData.serviceType}
              onChange={handleChange}
              options={serviceDropdownValues}
              required
            />
            {serviceOptionsLoading && (
              <div className="flex items-center">
                <p>Please wait...</p>
                <LoadingButton />
              </div>
            )}
          </FieldSectionCard>
          {remoteActivation === "no" && (
            <FieldSectionCard title="Appointment" columns={1}>
              <DateWindowSelector />
            </FieldSectionCard>
          )}

          {installType === "transfer" && (
            <>
              <FieldSectionCard title="Appointment" columns={2}>
                <FormField
                  label="Transfer Date *"
                  type="date"
                  name="transferDate"
                />
              </FieldSectionCard>
              <FieldSectionCard title="Additional Details" columns={3}>
                <FormField
                  label="Disconnect Date *"
                  type="date"
                  name="disconnectDate"
                />
                <FormField
                  label="Transfer Request Key*"
                  type="text"
                  name="transferKey"
                />
              </FieldSectionCard>
            </>
          )}

          <div className="flex flex-wrap sm:content-center gap-5 space-x-4 ml-4 mb-4">
            <button
              type="button"
              onClick={() => setFormOpen(false)}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitIsLoading || modemStatusLoading}
              className="bg-red-500 text-white py-2 px-4 rounded disabled:bg-gray-300 flex gap-2"
            >
              Submit{" "}
              {(submitIsLoading || modemStatusLoading) && <LoadingButton />}
            </button>
            {!isFormValid && (
              <div className="self-center"> Please Fill All Fields!</div>
            )}
          </div>
        </FieldSectionCard>
      </form>
    </div>
  );
};

export default CreateNewCustomerForm;
