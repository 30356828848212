import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import ModemDiagnosticInformation from "./ModemDiagnosticInformation";
import {
  useGetQuery,
  useImageMutation,
  usePostMutation,
} from "../../../redux/apis";
import Radio from "../../Radio";
import TextArea from "../../TextArea";
import DateWindowSelector from "../../DateWindowSelector";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner";
import { getQuestions } from "../../../utility/questions";
import DateWindowSelector2 from "../../DateWindowSelector2";

const RFTroubleshooting: React.FC<any> = ({ modemDetails }) => {
  const [skipGetQuestions, setSkipGetQuestions] = useState(false);
  const { customer } = useSelector((state: any) => state.customer);
  const { data, isLoading: getLoading } = useGetQuery(
    {
      actionName: "fetchtroubleshootingquestions",
      transactionData: { troubleshootingType: "RF Troubleshooting" },
    },
    { skip: skipGetQuestions }
  );
  const [rf, { isLoading }] = usePostMutation();
  const [image] = useImageMutation();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { person, address, modem, serviceType, technologyType } = customer;
    const {
      option1,
      option2,
      option3,
      preferredWindow1,
      preferredWindow2,
      preferredWindow3,
      buzzCode,
      phoneNumber,
      fileName,
      ...form
    }: any = Object.fromEntries(
      new FormData(e.target as HTMLFormElement).entries()
    );
    const troubleshooting = Object.entries(form).map(([key, value]) => ({
      questionId: key,
      response: value,
      tsType: "RF Troubleshooting",
    }));
    try {
      // if (fileName) {
      //   await image(fileName);
      // }
      const { data } = await rf({
        actionName: "submittroubleshooting",
        body: {
          createdBy: "joshua@ciphertv.com",
          person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
          address,
          modem,
          serviceType,
          technologyType,
          buzzCode,
          phoneNumber,
          timeSlot: {
            option1: moment(option1).format("MM/DD/YYYY"),
            option2: moment(option2).format("MM/DD/YYYY"),
            option3: moment(option3).format("MM/DD/YYYY"),
            preferredWindow1: preferredWindow1,
            preferredWindow2: preferredWindow2,
            preferredWindow3: preferredWindow3,
          },
          troubleshooting,
          fileName: fileName?.name || "",
        },
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      setSkipGetQuestions(true);
      e.target.reset();
    } catch (error) {}
  };

  if (getLoading) {
    return <LoadingSpinner />;
  }
  return (
    <FieldSectionCard title="RF Troubleshooting" columns={1}>
      <ModemDiagnosticInformation modemDetails={modemDetails} />
      <form onSubmit={handleSubmit}>
        {getQuestions(data)}
        <FormField
          label={"Buzz Code"}
          name="buzzCode"
          type="text"
          required={true}
        />
        <FormField
          label={"Phone Number"}
          name="phoneNumber"
          type="text"
          required={true}
        />
        <FieldSectionCard title="Time Slot" columns={1}>
          <DateWindowSelector2 />
        </FieldSectionCard>
        <div className="my-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-900"
            htmlFor="file_input"
          >
            Upload file
          </label>
          <input
            className="block p-2 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
            aria-describedby="file_input_help"
            id="file_input"
            type="file"
            name="fileName"
          />
        </div>
        <FormField
          label=""
          type="submit"
          buttonLabel="Submit"
          loading={isLoading}
          disable={isLoading}
        />
      </form>
    </FieldSectionCard>
  );
};

export default RFTroubleshooting;
