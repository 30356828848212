import React, { useState } from "react";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setsearchTransactionsList } from "../../redux/transactionSlice";
import { usePostCustomMutation, usePostMutation } from "../../redux/apis";
import LoadingSpinner from "../LoadingSpinner";
import LoadingButton from "../LoadingButton";
import TransactionsSearchList from "./TransactionsSearchList";

interface Transaction {
  trxId: number;
  workOrderId: string | null;
  trxType: string;
  rogersAccountNo: string;
  createdDate: string;
  status: string;
  [key: string]: any;
}

const SearchCriteria: React.FC = () => {
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const auth = queryParams.get("auth");
  const serviceid = queryParams.get("serviceid");
  const username = queryParams.get("username");

  const accountid = queryParams.get("accountid");
  const isp = queryParams.get("isp");
  const shaw = queryParams.get("shawId");

  let shawId = JSON.stringify(shaw);
  shawId = shawId.replace(/[^0-9.]/g, "");
  const prams = `?auth=${auth}&username=${username}&serviceid=${serviceid}&accountid=${accountid}&shawId=${shawId}&isp=${isp}`;

  const [formData, setFormData] = useState({
    transactionId: "",
    workOrderId: "",
    type: "",
    submissionDate: "",
    modifyDate: "",
    status: "",
    rogersAccount: "",
    region: "",
  });

  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);

  const [searchTransactions, { isLoading, isError }] = usePostCustomMutation();
  const [RogersTransactionFetch] = usePostMutation();

  useState(() => {
    setTypeOptions([
      "Cancel",
      "Change of Service",
      "DHCP Troubleshooting",
      "Disconnect",
      "Miscellaneous",
      "Modem Replacement",
      "Move",
      "New Install",
      "Provisioning Issue Troubleshooting",
      "RF Troubleshooting",
      "Reconnect",
      "Relocate Outlet",
      "Reschedule",
      "Service Routing Issue Troubleshooting",
      "Slow Speed Troubleshooting",
      "Transfer",
    ]);

    setStatusOptions([
      "In Progress",
      "Pending",
      "Open",
      "Cancelled",
      "Rejected",
      "Input Required",
      "Completed",
    ]);
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (name: string, date: Date | null) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : "";
    setFormData({
      ...formData,
      [name]: formattedDate,
    });
  };

  const handleReset = () => {
    setFormData({
      transactionId: "",
      workOrderId: "",
      type: "",
      submissionDate: "",
      modifyDate: "",
      status: "",
      rogersAccount: "",
      region: "",
    });
  };

  const handleSearch = async () => {
    const searchParams = {
      isp,
      trxId: formData.transactionId || "",
      workorderId: formData.workOrderId || "",
      txnType: formData.type || "",
      createdDate: formData.submissionDate || "",
      slaDate: "",
      modifyDate: formData.modifyDate || "",
      status: formData.status || "",
      technologyType: "",
      rogersAccountNo: formData.rogersAccount || "",
      faId: "",
      searchType: "searchBtn_empty",
      pageNumber: 1,
      sourceType: formData.region || "",
    };
    const { data } = await searchTransactions({
      actionName: "dashboard",
      transactionData: searchParams,
    });
    let sortedTransactions;
    if (data?.transaction?.length > 0) {
      sortedTransactions = [...data.transaction].sort(
        (a: Transaction, b: Transaction) => {
          const dateA: Date = new Date(a.modifyDate);
          const dateB: Date = new Date(b.modifyDate);
          return dateB.getTime() - dateA.getTime();
        }
      );
      dispatch(setsearchTransactionsList(sortedTransactions));
    } else if (
      formData.transactionId ||
      formData.workOrderId ||
      formData.rogersAccount
    ) {
      const { data } = await RogersTransactionFetch({
        actionName: "transaction_search",
        transactionData: {
          isp,
          trxId: formData.transactionId || "",
          workorderId: formData.workOrderId || "",
          txnType: "",
          createdDate: "",
          slaDate: "",
          modifyDate: "",
          status: "",
          technologyType: "",
          rogersAccountNo: formData.rogersAccount || "",
          faId: "",
          searchType: "searchBtn_empty",
          pageNumber: 1,
          sourceType: "",
        },
      });
      if (data?.transaction?.length > 0) {
        sortedTransactions = [...data.transaction].sort(
          (a: Transaction, b: Transaction) => {
            const dateA: Date = new Date(a.modifyDate);
            const dateB: Date = new Date(b.modifyDate);
            return dateB.getTime() - dateA.getTime();
          }
        );
        dispatch(setsearchTransactionsList(sortedTransactions));
      } else {
        dispatch(setsearchTransactionsList([]));
      }
    }
  };

  isError && console.log(isError);
  return (
    <div className="py-8">
      <div className="container mx-auto">
        <div className="flex justify-between">
          <h2 className="text-2xl font-normal text-gray-900 mb-2">
            Transactions Dashboard
          </h2>
        </div>
        <hr />
        <div className="border-b-2 border-primary w-24 mb-6"></div>

        <div className="bg-white rounded-lg shadow">
          <h3
            className="absolute text-lg font-semibold text-gray-900 ml-4"
            style={{ marginTop: "-12px" }}
          >
            Search Criteria
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-6 pt-10">
            {[
              "transactionId",
              "rogersAccount",
              "workOrderId",
              "type",
              "status",
              "submissionDate",
              "modifyDate",
            ].map((field, idx) => (
              <div key={idx}>
                <label
                  htmlFor={field}
                  className="block text-gray-700 font-normal mb-2"
                >
                  {field
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}
                </label>
                {field === "type" ||
                field === "status" ||
                field === "region" ? (
                  <select
                    id={field}
                    name={field}
                    value={(formData as any)[field]}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
                  >
                    <option value="">Select...</option>
                    {(field === "type"
                      ? typeOptions
                      : field === "status"
                      ? statusOptions
                      : ["East", "West"]
                    ).map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field === "submissionDate" || field === "modifyDate" ? (
                  <input
                    type="date"
                    value={
                      formData[field]
                        ? format(new Date(formData[field]), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      handleDateChange(
                        field,
                        e.target.value ? new Date(e.target.value) : null
                      )
                    }
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                ) : (
                  <input
                    id={field}
                    name={field}
                    type="text"
                    value={(formData as any)[field]}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder={field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  />
                )}
              </div>
            ))}
          </div>

          <div className="flex space-x-4 mt-6 p-6">
            <button
              onClick={handleReset}
              className="py-2 px-4 bg-secondary text-white rounded hover:bg-secondary-dark"
            >
              Reset
            </button>
            <button
              onClick={handleSearch}
              className="flex gap-2 py-2 px-4 bg-primary text-white rounded hover:bg-secondary-dark"
            >
              Search
              {isLoading && <LoadingButton />}
            </button>
          </div>
        </div>

        {isLoading && <LoadingSpinner />}

        {isError && (
          <div className="mt-3 rounded-md text-white bg-secondary text-center p-5 font-semibold">
            Failed to fetch transactions
          </div>
        )}

        <TransactionsSearchList prams={prams} />
      </div>
    </div>
  );
};

export default SearchCriteria;
