import React, { useState } from "react";
import FieldSectionCard from "../../FieldSectionCard";
import FormField from "../../FormField";
import {
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
} from "../../../redux/apis";
import LoadingSpinner from "../../LoadingSpinner";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { ispGetChangeofService } from "../../../utility/ispManagerPrams";

const ChangeSpeedTier: React.FC<any> = () => {
  const context = useOutletContext<any>();
  const { username, accountid, serviceid, shawId, isp } = context;

  const customerData = useSelector((state: any) => state.customer);
  const {
    customer: { modem, westFlag, mgig },
  } = customerData;
  const { data, isLoading } = useGetQuery({
    actionName: "servicerequests_getspeedtiervalues",
    transactionData: {
      mfrDescr: modem?.modemMfrDescr || null,
      modelCode: modem?.modemModel || null,
      deviceProdCode: modem?.modemProductCode || null,
      mgig: mgig || null,
      westFlag: westFlag || null,
    },
  });
  const [changeSpeed, { isLoading: postLoading }] = usePostMutation();
  const [speedTier, setSpeedTier] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const { customer } = customerData;
      const { address, modem, person, serviceType, technologyType } = customer;
      const obj = {
        createdBy: "joshua@ciphertv.com",
        firstName: person?.firstName || null,
        lastName: person?.lastName || null,
        changeOfSpeed: speedTier,
        rogersAccountNo: person?.rogersAccountNum || null,
      };
      const transactionData = {
        address,
        modem,
        person: { ...person, beCode: "WE12", tpiaName: "AEBC" },
        serviceType,
        technologyType,
        ...obj,
      };
      const { data } = await changeSpeed({
        actionName: "servicerequests_speedtier",
        transactionData,
        ispmgr: ispGetChangeofService(transactionData),
      });
      toast.info(
        data?.response ? `${data?.response}, ${data?.trxId}` : data?.responseMsg
      );
      e.target.reset();
    } catch (e) {}
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="p-4">
      <FieldSectionCard title="Speed Tier">
        <form onSubmit={handleSubmit} className="col-span-3">
          <FormField
            label="Speed Tier *"
            type="select"
            value={speedTier}
            onChange={(e) => setSpeedTier(e.target.value)}
            placeholder="Select..."
            options={
              data?.map((val: any) => ({ value: val, label: val })) || []
            }
          />
          <FormField
            label=""
            type="submit"
            buttonLabel="Submit"
            disable={postLoading}
            loading={postLoading}
          />
        </form>
      </FieldSectionCard>
    </div>
  );
};

export default ChangeSpeedTier;
