import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
} from "../../redux/apis";
import {
  setCurrentTransaction,
  setCurrentTransactionHostory,
} from "../../redux/transactionSlice";
import { RootState } from "../../redux/store";
import LoadingSpinner from "../LoadingSpinner";
import LoadingButton from "../LoadingButton";
import AccountInformationView from "../TransactionScreen/AccountInfomationView";
import { GrView } from "react-icons/gr";
import Modal from "../Model";
import { toast } from "react-toastify";
import FormField from "../FormField";
import NewInstallationView from "../TransactionScreen/NewInstallationView";
import { OpenTransactionView } from "../TransactionScreen/OpenTransactionView";
import { setCustomer } from "../../redux/customerSlice";
const ViewTransaction: React.FC = () => {
  const [UpdateTransaction, { isLoading: postLoading }] = usePostMutation();

  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTransactionRequest, setSelectedTransactionRequest] =
    useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState("");

  const [note, setNote] = useState("");

  const { id } = useParams<{ id: string }>();
  const currentTransaction = useSelector(
    (state: RootState) => state.transaction.currentTransaction
  );
  const dispatch = useDispatch();
  const customer = useSelector((state: RootState) => state.customer.customer);

  const {
    data: dataCustomer,
    isLoadingCustomer,
    isErrorCustomer,
  } = useGetQuery(
    {
      actionName: "servicerequests_account",
      transactionData: {
        rogersAccountNumber: currentTransaction?.rogersAccountNo,
      },
    },
    { skip: customer.person || !currentTransaction?.rogersAccountNo }
  );

  if (dataCustomer?.person?.rogersAccountNum) {
    dispatch(setCustomer(dataCustomer));
  }

  const handleViewClick = async (request: any) => {
    const data = request.includes("{") ? JSON.parse(request) : request;
    // setSelectedTransactionRequest(data);
    setShowDetails(!showDetails);
  };

  const handleCloseModal = () => {
    setShowDetails(!showDetails);
  };

  const [getTransactionDetails, { data, isLoading, isError }] =
    useLazyGetQuery();
  const [
    getTransactionHistory,
    {
      data: historyData,
      isLoading: historyLoading,
      isError: historyError,
      refetch,
    },
  ] = useLazyGetQuery();

  const handleDropdownChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const action = event.target.value;
    setIsOpenUpdate(action);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const handleUpdate = async () => {
    const { person, order, address, technologyType } = customer;
    if (
      selectedTransactionRequest?.status === "Completed" ||
      selectedTransactionRequest?.status === "Rejected" ||
      selectedTransactionRequest?.status === "Accepted"
    ) {
      try {
        await UpdateTransaction({
          actionName: "transaction_update",
          transactionData: {
            txnId: selectedTransactionRequest?.trxId,
            modifyBy: "joshua@ciphertv.com",
            loggedUser: "jielin",
          },
        });
        const { data } = await UpdateTransaction({
          actionName: "transaction_submit",
          transactionData: {
            modifyBy: "joshua@ciphertv.com",
            txnId: selectedTransactionRequest?.trxId,
            trxType: selectedTransactionRequest?.trxType,
            technologyType,
            rogersAccountNo: person.rogersAccountNum,
            status: isOpenUpdate,
            notes: note,
            slaDate: null,
            workorderId: null,
            ticketId: null,
            serviceCallId: null,
            transactionLockFlag: null,
            workorderStatus: null,
            ticketStatus: null,
            serviceCallStatus: null,
            faId: null,
            gponOrderId: null,
            gponOrderStatus: null,
          },
        });
        toast(`${data.response} ` || "Successful");

        const { data: dataRefetch } = await getTransactionHistory({
          actionName: "transaction_history",
          transactionData: { transactionId: id },
        });
        if (!historyLoading && dataRefetch?.audit) {
          dispatch(setCurrentTransactionHostory(dataRefetch?.audit));
        }
      } catch (error) {}
    } else {
      try {
        await UpdateTransaction({
          actionName: "transaction_update",
          transactionData: {
            txnId: selectedTransactionRequest?.trxId,
            modifyBy: "joshua@ciphertv.com",
            loggedUser: "jielin",
          },
        });
        const { data } = await UpdateTransaction({
          actionName: "transaction_submit",
          transactionData: {
            modifyBy: "joshua@ciphertv.com",
            txnId: selectedTransactionRequest?.trxId,
            trxType: selectedTransactionRequest?.trxType,
            technologyType,
            rogersAccountNo: person.rogersAccountNum,
            status: isOpenUpdate,
            notes: note,
            slaDate: null,
            workorderId: null,
            ticketId: null,
            serviceCallId: null,
            transactionLockFlag: null,
            workorderStatus: null,
            ticketStatus: null,
            serviceCallStatus: null,
            faId: null,
            gponOrderId: null,
            gponOrderStatus: null,
          },
        });
        const { data: dataRefetch } = await getTransactionHistory({
          actionName: "transaction_history",
          transactionData: { transactionId: id },
        });
        if (!historyLoading && dataRefetch?.audit) {
          dispatch(setCurrentTransactionHostory(dataRefetch?.audit));
        }
        toast(`${data.response} ` || "Successful");
      } catch (error) {}
    }
    setIsOpenUpdate(""); // Close the modal after submission
  };

  useEffect(() => {
    if (id) {
      getTransactionDetails({
        actionName: "transaction_view",
        transactionData: { transactionId: id },
      })
        .then((response: any) => {
          if (response.data.transaction[0]) {
            dispatch(setCurrentTransaction(response.data.transaction[0]));
            setSelectedTransactionRequest(response.data.transaction[0]);
          }
        })
        .then(async () => {
          const { data } = await getTransactionHistory({
            actionName: "transaction_history",
            transactionData: { transactionId: id },
          });
          if (!historyLoading && data?.audit) {
            dispatch(setCurrentTransactionHostory(data?.audit));
          }
        })
        .catch((error: any) => {});
    }
  }, [id, getTransactionDetails, getTransactionHistory, dispatch]);

  if (isLoadingCustomer) return <LoadingSpinner />;
  if (isErrorCustomer) return <div>Error occurred</div>;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  // if (isError) {
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       <div className="text-red-500">Failed to load transaction details.</div>
  //     </div>
  //   );
  // }

  return (
    <div className="p-8">
      <div className="container mx-auto">
        <h2 className="text-2xl font-normal text-gray-900 mb-2">
          Transaction Details
        </h2>
        <hr />
        <div className="border-b-2 border-primary w-24 mb-6"></div>
        {!isOpen ? (
          <>
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Summary
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Transaction ID
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.trxId}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Type
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.trxType || "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Creation Date
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.createdDate || "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Created By
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.createdBy || "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Rogers Account Number
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.rogersAccountNo || "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Work Order ID
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.workorderId ||
                      currentTransaction?.workOrderId ||
                      "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Service Request/Ticket ID
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.ticketId || "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Service Call Work Order ID
                  </label>
                  <p className="py-2 px-4 border rounded">
                    {currentTransaction?.serviceCallId || "-"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-700 font-normal mb-2">
                    Status
                  </label>
                  <div className="w-96 flex gap-5">
                    <p className="w-56 px-5 py-2 border rounded">
                      {currentTransaction?.status || "-"}
                    </p>
                    {(currentTransaction?.status === "Open" ||
                      currentTransaction?.status === "In Progress" ||
                      currentTransaction?.status === "Input Required") && (
                      <select
                        onChange={handleDropdownChange}
                        className="mt-1 block w-full md:w-1/2 lg:min-w-52 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="">Select Action</option>
                        <option value="Open">Open</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="mt-4 py-2 px-4 bg-primary text-white rounded hover:bg-red-700"
                onClick={() => setIsOpen(true)}
              >
                Open Request
              </button>
            </div>
            <div className="mt-8 bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">
                Transaction History
              </h3>
              {historyError && (
                <div className="m-5 rounded-md text-white bg-secondary text-center p-5 font-semibold">
                  Unable to fetch record
                </div>
              )}
              <table className="min-w-full bg-white border border-gray-200 p-3 overflow-x-auto">
                <thead>
                  <tr className="bg-secondary text-white">
                    <th className="py-2 px-3 p-0 border-b">Date</th>
                    <th className="py-2 p-0 border-b">Status</th>
                    <th className="py-2 p-0 border-b">Updated By</th>
                    <th className="py-2 p-0 border-b">Notes</th>
                    <th className="py-2 p-0 border-b"></th>
                  </tr>
                </thead>
                {historyLoading && (
                  <div className="w-full p-5 text-center">
                    <LoadingButton />
                  </div>
                )}
                <tbody>
                  {currentTransaction?.history?.map(
                    (historyItem: any, index: any) => (
                      <tr key={index} className="text-center">
                        <td className="py-2 px-4 border-b">
                          {historyItem.modifyDate}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {historyItem.status}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {historyItem.modifyBy}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {historyItem.notes}
                        </td>
                        <td className="py-2 px-4 border-b">
                          <GrView
                            onClick={() => handleViewClick(historyItem.request)}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-6 flex space-x-4">
              <button
                onClick={() => navigate(-1)}
                className="py-2 px-4 bg-primary text-white rounded hover:bg-red-700"
              >
                Return to Dashboard
              </button>
            </div>
          </>
        ) : (
          <>
            {currentTransaction?.trxType === "New Install" ? (
              <NewInstallationView id={id} />
            ) : (
              <>
                <AccountInformationView
                  id={currentTransaction?.rogersAccountNo}
                />
                <OpenTransactionView
                  requestType={currentTransaction?.trxType}
                  id={currentTransaction?.trxId}
                />
              </>
            )}
            <button
              className="mt-4 py-2 px-4 bg-primary text-white rounded hover:bg-red-700"
              onClick={() => setIsOpen(false)}
            >
              Back to Transaction
            </button>
          </>
        )}
      </div>
      {showDetails && (
        <Modal data={selectedTransactionRequest} onClose={handleCloseModal} />
      )}
      {isOpenUpdate && (
        <Modal onClose={() => setIsOpenUpdate("")}>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">
            {isOpenUpdate === "Cancelled"
              ? "Cancel Transaction"
              : `${isOpenUpdate} Transaction`}
          </h3>
          <FormField
            label="Note"
            type="textarea"
            value={note}
            onChange={(e: any) => handleNoteChange(e)}
            placeholder="Enter note"
          />
          <button
            onClick={handleUpdate}
            className="py-2 px-4 bg-primary text-white rounded hover:bg-red-700 flex gap-2"
            disabled={postLoading}
          >
            {isOpenUpdate === "Cancelled"
              ? "Submit Cancellation"
              : "Update Transaction"}{" "}
            {postLoading && <LoadingButton />}
          </button>
        </Modal>
      )}
    </div>
  );
};

export default ViewTransaction;
